import { Link } from "@chakra-ui/next-js";
import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Subheading } from "~/components";
import { GetStrapiServerSideProps } from "~/strapi";
import { NextPageWithLayout } from "~/types";

const ErrorPage: NextPageWithLayout = () => {
  const footerImage = useBreakpointValue({
    base: "/MapLines.png",
  });

  const padding = useBreakpointValue({ base: "6%", md: "14%", lg: "25%" });

  return (
    <Box
      bgColor="gray.900"
      minH="100vh"
      overflow="hidden"
      position="relative"
      display="flex"
      flexDirection="column"
      padding={padding}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="40px"
        mt={{ lg: "-25%" }}
        mb={{ lg: "15%" }}
      >
        <Subheading color="yellow.500" fontSize={{ base: "2xl", md: "4xl" }}>
          404 error
        </Subheading>
        <Heading
          fontWeight="bold"
          color="white"
          textAlign="center"
          fontSize={{ base: "2xl", md: "4xl" }}
        >
          We can’t find the page you’re looking for
        </Heading>
        <Text
          color="white"
          textAlign="center"
          fontSize={{ base: "md", md: "lg" }}
        >
          This page may have been moved, renamed or is temporarily unavailable.
          Please check the URL or go back to the home page.
        </Text>

        <Button bgColor="yellow.500" color="black" as={Link} href={`/`}>
          Back to home
        </Button>
      </Box>
      <AspectRatio
        pointerEvents="none"
        position="absolute"
        bottom={0}
        w={{ base: "100%" }}
        ratio={{ base: 4 / 3, md: 16 / 9 }}
      >
        <Image bgSize="contain" src={footerImage} alt="yellow and blue lines" />
      </AspectRatio>
    </Box>
  );
};

ErrorPage.layoutOptions = { showFooter: false };

export default ErrorPage;

export const getStaticProps: GetStrapiServerSideProps = async () => {
  return { props: { pageData: { seo: { metaTitle: "Hwy3 - Not Found" } } } };
};
